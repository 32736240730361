import React, { useEffect, useState } from 'react';
import { Avatar, Box, CircularProgress, Stack, Switch, Typography, } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { calculateTimeLeft } from './utils';
import useSnackbar from '../../../hooks/useSnackbar';
import { useUpdateTokenStatus } from '../../../hooks/useUpdateTokenStatus';
import api from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';
import { getDateTimeText } from '../../../services/utils';
import { User } from '../../../context/UserContext';
import { ReactComponent as TimerIcon } from '../../../icons/timer.svg';

export interface Token {
    id: number;
    createdAt: Date;
    itemName: string;
    itemDescription: string;
    quantity: number;
    price: number | string;
    discount: number;
    expirationDate: Date;
    imageFile: File | null;
    imageUrl: string | null;
    isActive: boolean;
    merchant: User;
}

const TokenDetailsPage: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [token, setToken] = useState<Token | null>(null);
    const [loading, setLoading] = useState(true);
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const {updatingTokenId, updateTokenStatus} = useUpdateTokenStatus(showSnackbar);

    const updateLocalTokenStatus = (tokenId: number, newStatus: boolean) => {
        if (!token) return;
        setToken({...token, isActive: newStatus});
    };

    useEffect(() => {
        const fetchToken = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/tokens/${id}`);
                const tokenData = response.data.data;
                setToken({
                    id: tokenData.id,
                    ...tokenData.attributes,
                });
            } catch (error) {
                showSnackbar('Error fetching token details', 'error');
                console.error('Error fetching token details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchToken();
    }, [id, showSnackbar]);

    const handleStatusToggle = () => {
        if (!token) return;
        const newStatus = !token.isActive;
        updateTokenStatus(token, newStatus, updateLocalTokenStatus);
    };

    if (loading) {
        return (
            <BasePageLayout>
                <Box display="flex" justifyContent="center" alignItems="center" height="calc(100dvh - 180px)">
                    <CircularProgress/>
                </Box>
                <SnackbarComponent/>
            </BasePageLayout>
        );
    }

    if (!token) {
        return (
            <BasePageLayout>
                <Typography>No token found.</Typography>
                <SnackbarComponent/>
            </BasePageLayout>
        );
    }

    const isExpired = new Date(token.expirationDate) < new Date();
    const timeLeft = calculateTimeLeft(new Date(token.expirationDate));

    return (
        <BasePageLayout>
            <Box sx={{mb: 2}}>
                <Typography fontSize={16} mb={0}>
                    Token Details
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
                p={2}
                gap={2}
                sx={{
                    bgcolor: 'rgba(245, 245, 245, 1)',
                    borderRadius: '12px'
                }}
            >
                <Box display="flex">
                    <Avatar
                        sx={{
                            bgcolor: 'rgba(57, 57, 67, 1)',
                            mr: 3,
                            border: token.imageUrl ? '' : '1px solid black',
                            height: 77,
                            width: 77,
                        }}
                        src={token.imageUrl || ''}
                    >
                        {!token.imageUrl && <ImageOutlined fontSize='large' sx={{color: 'white'}}/>}
                    </Avatar>
                    <Stack>
                        <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                            {token.itemName} ({token.quantity})
                        </Typography>
                        <Typography
                            fontWeight={500}
                            fontSize={10}
                            sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                        >
                            Issued: {getDateTimeText(token.createdAt)}
                        </Typography>
                        {isExpired ? (<Typography
                            fontWeight={500}
                            fontSize={10}
                            color='error'
                        >
                            Expired: {getDateTimeText(token.expirationDate)}
                        </Typography>) : (
                            <Box display='flex' alignItems='center' gap='5px' sx={{
                                color: token.isActive ? 'rgba(59, 186, 79, 1)' : 'rgba(132, 132, 132, 1)',
                            }}>
                                <TimerIcon/>
                                <Typography
                                    fontWeight={700}
                                    color="inherit"
                                    fontSize={10}
                                    mb={0}
                                >
                                    {timeLeft}
                                </Typography>
                            </Box>
                        )}
                        <Typography fontWeight={500}
                                    fontSize={10}
                                    sx={{
                                        mt: 1
                                    }}>
                            <Box component="span" sx={{color: 'rgba(21, 21, 33, 0.5)'}}>Discount Applied:</Box>{'  '}
                            <Box component="span" sx={{color: 'rgba(26, 29, 37, 1)'}}>{token.discount || 0}%</Box>
                        </Typography>

                        <Typography fontWeight={500}
                                    fontSize={10}>
                            <Box component="span" sx={{color: 'rgba(21, 21, 33, 0.5)'}}>Price/ea:</Box>{'  '}
                            <Box component="span" sx={{color: 'rgba(26, 29, 37, 1)'}}>${(+token.price).toFixed(2)}</Box>
                        </Typography>

                        <Typography fontWeight={500}
                                    fontSize={10}>
                            <Box component="span" sx={{color: 'rgba(21, 21, 33, 0.5)'}}>Qty:</Box>{'  '}
                            <Box component="span" sx={{color: 'rgba(26, 29, 37, 1)'}}>{token.quantity}</Box>
                        </Typography>

                        <Typography variant="h6" fontWeight={700} fontSize={17}>
                            Total Value:
                            ${(+token.price * token.quantity * (1 - token.discount / 100)).toFixed(2)}
                        </Typography>

                        <Typography fontSize={10} mb={0} mt='10px'>{token.itemDescription}</Typography>
                    </Stack>
                </Box>

                <Stack alignItems="center" alignSelf='baseline'>
                    <Typography
                        fontSize={12}
                        mb={0}
                        color={isExpired ? 'error' : 'textSecondary'}
                    >
                        {isExpired ? 'Expired' : token.isActive ? 'Active' : 'Inactive'}
                    </Typography>
                    {!isExpired && (
                        <Switch
                            checked={token.isActive}
                            onChange={handleStatusToggle}
                            disabled={updatingTokenId === token.id}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: 'rgba(59, 186, 79, 1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(59, 186, 79, 0.2)',
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: 'rgba(59, 186, 79, 1)',
                                },
                                '& .MuiSwitch-switchBase.Mui-disabled': {
                                    color: 'rgba(59, 186, 79, 1)',
                                    opacity: 0.5,
                                },
                                '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
                                    backgroundColor: 'rgba(59, 186, 79, 1)',
                                    opacity: 0.5,
                                },
                                '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
                                    color: 'rgba(59, 186, 79, 1)',
                                    opacity: 0.5,
                                },
                                '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: 'rgba(59, 186, 79, 1)',
                                    opacity: 0.5,
                                },
                            }}
                        />
                    )}
                </Stack>
            </Box>
            <SnackbarComponent/>
        </BasePageLayout>
    );
};

export default TokenDetailsPage;