import React, { useContext } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Loading from './Loading';

export enum Role {
    Merchant = 'Merchant',
    User = 'User'
}

interface PrivateRouteProps {
    element: React.ReactElement;
    role: Role;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({element, role}) => {
    const {user, loading} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const phoneNumber = searchParams.get("phoneNumber");
    const location = useLocation();


    if (loading) {
        return <Loading/>;
    }

    if (!user) {
        return role === Role.Merchant ? <Navigate to="/merchant/login"/> :
            <Navigate to="/login" state={phoneNumber ? {phoneNumber, url: location.pathname} : {}}/>;
    }

    if (user.role !== role) {
        return <Navigate to="/"/>;
    }

    return element;
};

export default PrivateRoute;