export const calculateTimeLeft = (expirationDate: Date, convertDaysToHours = false): string => {
    const now = new Date();
    const diffInMs = expirationDate.getTime() - now.getTime();

    if (diffInMs <= 0) return 'Expired';

    const totalHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

    if (convertDaysToHours) {
        return `${totalHours}h ${minutes}min`;
    }

    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;

    return `${days}d ${hours}h ${minutes}min`;
};