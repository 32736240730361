import BasePageLayout from '../../components/BasePageLayout';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { Check, ContentCopy } from '@mui/icons-material';
import IosShareIcon from '@mui/icons-material/IosShare';
import useSnackbar from '../../hooks/useSnackbar';

const AllRight = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {shareUrl, shareText} = location.state || {};
    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const handleCopyUrl = async () => {
        await navigator.clipboard.writeText(shareText + shareUrl);
        showSnackbar('Copied successfully', 'success');
    };

    const shareData = {
        title: 'Token Sharing',
        text: shareText,
        url: shareUrl,
    }

    const handleShare = async () => {
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error('Error sharing', err);
        }
    };

    return (
        <BasePageLayout showBack={false}>
            <Stack justifyContent='space-around' alignItems='center' height={'calc(90dvh - 90px - 90px)'}>
                <Stack alignItems={'center'}>
                    <Box height={72} width={72} bgcolor={'rgba(255, 166, 41, 0.1)'} display={'flex'} borderRadius='50%'
                         alignItems='center' justifyContent='center'>
                        <Box height={33} width={33} bgcolor={'rgba(255, 166, 41, 1)'} display={'flex'}
                             borderRadius='50%' alignItems='center' justifyContent='center'>
                            <Check fontSize={'small'} sx={{color: 'white'}}/>
                        </Box>
                    </Box>
                    <Typography color={'rgba(18, 18, 18, 1)'} fontSize={20} fontWeight={500}>All right!</Typography>
                    <Typography color={'rgba(71, 71, 71, 1)'} fontSize={14}>Tossed!</Typography>
                </Stack>

                {shareUrl && shareText && (
                    <Stack>
                        <Typography>Please forward this link to the recipient of the token:</Typography>
                        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                            <Typography mb={0} fontSize={12}
                                        sx={{wordBreak: 'break-all'}}>{shareText} {shareUrl}</Typography>
                            <Box display="flex" gap={1}>
                                <IconButton onClick={handleCopyUrl}>
                                    <ContentCopy fontSize="small"/>
                                </IconButton>
                                {navigator.canShare && navigator.canShare(shareData) && <IconButton
                                    aria-label="share"
                                    onClick={handleShare}
                                >
                                    <IosShareIcon/>
                                </IconButton>}
                            </Box>
                        </Box>
                    </Stack>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate('/home')}
                >
                    Home
                </Button>
            </Stack>
            <SnackbarComponent/>
        </BasePageLayout>
    );
};

export default AllRight;