import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    Paper,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import { Close, ImageOutlined, } from '@mui/icons-material';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { calculateTimeLeft } from './utils';
import throttle from 'lodash/throttle';
import TuneIcon from '@mui/icons-material/Tune';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Token } from './TokenDetailsPage';
import { UserContext } from '../../../context/UserContext';
import useSnackbar from '../../../hooks/useSnackbar';
import { useUpdateTokenStatus } from '../../../hooks/useUpdateTokenStatus';
import api from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';
import { getDateTimeText } from '../../../services/utils';
import { ReactComponent as TimerIcon } from '../../../icons/timer.svg';

type FilterState = {
    active: boolean;
    expired: boolean;
    inactive: boolean;
    showAll: boolean;
};

type FilterKey = keyof FilterState;

const TokensPage = () => {
    const {user} = useContext(UserContext);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [filterOpen, setFilterOpen] = useState(false);

    const initialFilterState: FilterState = {
        active: false,
        expired: false,
        inactive: false,
        showAll: false,
    };

    const [filter, setFilter] = useState<FilterState>(initialFilterState);
    const [appliedFilters, setAppliedFilters] = useState<FilterState>(initialFilterState);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const {showSnackbar, SnackbarComponent} = useSnackbar();
    const navigate = useNavigate();

    const {updatingTokenId, updateTokenStatus} = useUpdateTokenStatus(showSnackbar);

    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const updateLocalTokenStatus = (tokenId: number, newStatus: boolean) => {
        setTokens((prevTokens) =>
            prevTokens.map((token) =>
                token.id === tokenId ? {...token, isActive: newStatus} : token
            )
        );
    };

    const fetchTokens = useCallback(
        async (page: number, reset = false) => {
            if (!user) {
                return;
            }

            setIsFetching(true);
            try {
                const conditions = [];

                conditions.push({merchant: {id: {$eq: user.id}}});

                if (appliedFilters.showAll) {
                } else {
                    if (appliedFilters.active) {
                        conditions.push({
                            $and: [
                                {isActive: true},
                                {expirationDate: {$gte: new Date().toISOString()}},
                            ],
                        });
                    }

                    if (appliedFilters.inactive) {
                        conditions.push({
                            $and: [
                                {isActive: false},
                                {expirationDate: {$gte: new Date().toISOString()}},
                            ],
                        });
                    }

                    if (appliedFilters.expired) {
                        conditions.push({
                            expirationDate: {$lt: new Date().toISOString()},
                        });
                    }
                }

                const query = qs.stringify(
                    {
                        sort: ['createdAt:desc'],
                        pagination: {
                            page,
                            pageSize: 10,
                        },
                        filters: conditions.length > 0 ? {$and: conditions} : {},
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/tokens?${query}`);

                const fetchedTokens = response.data.data.map((token: any) => ({
                    id: token.id,
                    ...token.attributes,
                }));

                const {pageCount} = response.data.meta.pagination;
                setPageCount(pageCount);

                setTokens((prevTokens) =>
                    reset ? fetchedTokens : [...prevTokens, ...fetchedTokens]
                );
            } catch (error) {
                setError('Error fetching tokens');
                showSnackbar('Error fetching tokens', 'error');
                console.error('Error fetching tokens:', error);
            } finally {
                setInitialLoading(false);
                setIsFetching(false);
            }
        },
        [appliedFilters, showSnackbar, user]
    );

    useEffect(() => {
        fetchTokens(1, true);
    }, [fetchTokens]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (
                window.innerHeight + document.documentElement.scrollTop + 100 >=
                document.documentElement.scrollHeight &&
                !isFetching &&
                page < pageCount
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        }, 200);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            handleScroll.cancel();
        };
    }, [isFetching, page, pageCount]);

    useEffect(() => {
        if (page > 1) {
            fetchTokens(page);
        }
    }, [page, fetchTokens]);

    const handleFilterChange = (field: FilterKey) => {
        setFilter((prev) => {
            const newValue = !prev[field];

            const newFilter = {
                ...prev,
                [field]: newValue,
            };

            const allFiltersSelected = (['active', 'inactive', 'expired'] as FilterKey[]).every(
                (filterKey) => newFilter[filterKey]
            );

            return {
                ...newFilter,
                showAll: allFiltersSelected,
            };
        });
    };

    const handleApplyFilter = () => {
        setAppliedFilters({...filter});
        setPage(1);
        setTokens([]);
        setFilterOpen(false);
    };

    const handleClearFilters = () => {
        setFilter(initialFilterState);
        setAppliedFilters(initialFilterState);
        setPage(1);
        setTokens([]);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (filterButtonRef.current && filterButtonRef.current.contains(event.target as Node)) {
            return;
        }
        setFilterOpen(false);
    };

    const handleStatusToggle = (token: Token) => {
        const newStatus = !token.isActive;
        updateTokenStatus(token, newStatus, updateLocalTokenStatus);
    };

    const switchStyles = {
        checkedColor: 'rgba(59, 186, 79, 1)',
        hoverBackground: 'rgba(59, 186, 79, 0.2)',
        opacityDisabled: 0.5,
    };

    const hasActiveFilters = () => {
        const {showAll, ...otherFilters} = appliedFilters;
        return Object.values(otherFilters).some((value) => value);
    };

    return (
        <BasePageLayout>
            <Box
                sx={{
                    mb: 2,
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontSize={16} mb={0}>
                    Tokens
                </Typography>
                <Box position="relative">
                    <Button
                        ref={filterButtonRef}
                        sx={{
                            height: '25px',
                            width: '60px',
                            padding: 0,
                            backgroundColor: 'rgba(245, 245, 245, 1)',
                            color: 'rgba(57, 57, 67, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(57, 57, 67, 0.05)',
                            },
                        }}
                        onClick={() => setFilterOpen((prev) => !prev)}
                        startIcon={<TuneIcon sx={{fontSize: '12px !important'}}/>}
                        endIcon={<KeyboardArrowDownIcon sx={{fontSize: '12px !important'}}/>}
                    ></Button>
                    {filterOpen && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Paper
                                elevation={3}
                                sx={{
                                    position: 'fixed',
                                    mt: '16px',
                                    zIndex: 10,
                                    bgcolor: 'white',
                                    borderRadius: '12px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    p: 2,
                                    width: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transform: 'translateX(-72%)'
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.showAll}
                                            onChange={() => {
                                                const allSelected = !filter.showAll;
                                                setFilter({
                                                    active: allSelected,
                                                    expired: allSelected,
                                                    inactive: allSelected,
                                                    showAll: allSelected,
                                                });
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                color: 'rgba(132, 132, 132, 1)',
                                                marginBottom: 0,
                                            }}
                                        >
                                            Show All
                                        </Typography>
                                    }
                                    disableTypography
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.active}
                                            onChange={() => handleFilterChange('active')}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                color: 'rgba(132, 132, 132, 1)',
                                                marginBottom: 0,
                                            }}
                                        >
                                            Active
                                        </Typography>
                                    }
                                    disableTypography
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.expired}
                                            onChange={() => handleFilterChange('expired')}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                color: 'rgba(132, 132, 132, 1)',
                                                marginBottom: 0,
                                            }}
                                        >
                                            Expired
                                        </Typography>
                                    }
                                    disableTypography
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.inactive}
                                            onChange={() => handleFilterChange('inactive')}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                color: 'rgba(132, 132, 132, 1)',
                                                marginBottom: 0,
                                            }}
                                        >
                                            Inactive
                                        </Typography>
                                    }
                                    disableTypography
                                />
                                <Button
                                    sx={{
                                        width: 'fit-content',
                                        alignSelf: 'end',
                                        backgroundColor: 'transparent',
                                        color: 'rgba(57, 57, 67, 1)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(245, 245, 245, 1)',
                                        },
                                    }}
                                    onClick={handleApplyFilter}
                                >
                                    Apply
                                </Button>
                            </Paper>
                        </ClickAwayListener>
                    )}
                </Box>
            </Box>

            {hasActiveFilters() && (
                <Box sx={{mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1}}>
                    {Object.entries(appliedFilters).map(([key, value]) => {
                        if (key !== 'showAll' && value) {
                            return (
                                <Chip
                                    key={key}
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    size="small"
                                    deleteIcon={<Close sx={{fontSize: '12px !important', color: 'white !important'}}/>}
                                    sx={{
                                        fontSize: '10px',
                                        fontWeight: '500',
                                        borderRadius: '9px',
                                        backgroundColor: 'rgba(57, 57, 67, 1)',
                                        color: 'white !important',
                                    }}
                                    onDelete={() => {
                                        setFilter((prev) => ({
                                            ...prev,
                                            [key]: false,
                                            showAll: false,
                                        }));
                                        setAppliedFilters((prev) => ({
                                            ...prev,
                                            [key]: false,
                                            showAll: false,
                                        }));
                                        setPage(1);
                                        setTokens([]);
                                    }}
                                />
                            );
                        }
                        return null;
                    })}

                    <Chip
                        label="Clear all"
                        size="small"
                        deleteIcon={<Close sx={{fontSize: '12px !important'}}/>}
                        sx={{
                            fontSize: '10px',
                            fontWeight: '500',
                            borderRadius: '9px',
                            border: '1px solid rgba(0, 0, 0, 0.5)',
                            backgroundColor: 'white',
                            color: 'rgba(28, 27, 31, 1) !important',
                        }}
                        onDelete={handleClearFilters}
                    />
                </Box>
            )}

            <SnackbarComponent/>

            {error ? (
                <Typography color="error">{error}</Typography>
            ) : initialLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={1}
                    height="calc(100dvh - 180px)"
                >
                    <CircularProgress/>
                </Box>
            ) : (
                <Box>
                    {tokens.length > 0 ? (
                        tokens.map((token: Token) => {
                            const isExpired = new Date(token.expirationDate) < new Date();
                            const timeLeft = calculateTimeLeft(new Date(token.expirationDate));

                            return (
                                <Box
                                    key={token.id}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={1.5}
                                    p={2}
                                    gap={2}
                                    sx={{
                                        bgcolor: 'rgba(245, 245, 245, 1)',
                                        borderRadius: '12px',
                                        opacity: isExpired ? 0.5 : 1,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: isExpired ? 'default' : 'rgba(245, 245, 245, 0.8)',
                                        },
                                    }}
                                    onClick={() => navigate(`/merchant/tokens/${token.id}`)}
                                >
                                    <Box display="flex">
                                        <Avatar
                                            sx={{
                                                bgcolor: 'rgba(57, 57, 67, 1)',
                                                mr: 2,
                                                border: token.imageUrl ? '' : '1px solid black',
                                                height: 40,
                                                width: 40,
                                            }}
                                            src={token.imageUrl || ''}
                                        >
                                            {!token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                                        </Avatar>
                                        <Stack>
                                            <Typography
                                                fontWeight={600}
                                                sx={{
                                                    color: 'rgba(26, 29, 37, 1)',
                                                }}
                                            >
                                                {token.itemName} ({token.quantity})
                                            </Typography>
                                            <Typography
                                                fontWeight={500}
                                                fontSize={10}
                                                sx={{
                                                    color: 'rgba(21, 21, 33, 0.5)',
                                                }}
                                            >
                                                {getDateTimeText(token.createdAt)}
                                            </Typography>
                                        </Stack>
                                    </Box>

                                    <Box display="flex" alignItems="center" gap={3}>
                                        {!isExpired && (
                                            <Stack
                                                alignItems="center"
                                                sx={{
                                                    color: token.isActive ? 'rgba(59, 186, 79, 1)' : 'rgba(132, 132, 132, 1)',
                                                }}
                                            >
                                                <TimerIcon/>
                                                <Typography textAlign='center' fontWeight={700} fontSize={10}
                                                            color="inherit">
                                                    {timeLeft}
                                                </Typography>
                                            </Stack>
                                        )}

                                        <Stack alignItems="end">
                                            <Typography
                                                fontSize={12}
                                                fontWeight={500}
                                                mb={isExpired ? 1 : 0}
                                                color={isExpired ? 'error' : 'textSecondary'}
                                                mr={1.5}
                                            >
                                                {isExpired ? 'Expired' : token.isActive ? 'Active' : 'Inactive'}
                                            </Typography>
                                            {isExpired ? (<Typography
                                                fontWeight={500}
                                                fontSize={10}
                                                mr={1.5}
                                                color='error'
                                            >
                                                {getDateTimeText(token.expirationDate)}
                                            </Typography>) : (
                                                <Switch
                                                    checked={token.isActive}
                                                    onChange={() => handleStatusToggle(token)}
                                                    onClick={(e) => e.stopPropagation()}
                                                    disabled={updatingTokenId === token.id}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                            color: switchStyles.checkedColor,
                                                            '&:hover': {
                                                                backgroundColor: switchStyles.hoverBackground,
                                                            },
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: switchStyles.checkedColor,
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-disabled': {
                                                            color: switchStyles.checkedColor,
                                                            opacity: switchStyles.opacityDisabled,
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
                                                            backgroundColor: switchStyles.checkedColor,
                                                            opacity: switchStyles.opacityDisabled,
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
                                                            color: switchStyles.checkedColor,
                                                            opacity: switchStyles.opacityDisabled,
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: switchStyles.checkedColor,
                                                            opacity: switchStyles.opacityDisabled,
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                    </Box>
                                </Box>
                            );
                        })
                    ) : (
                        <Typography>No tokens available.</Typography>
                    )}
                </Box>
            )}

            {isFetching && (
                <Box display="flex" justifyContent="center" alignItems="center" zIndex={1} mt={2}>
                    <CircularProgress/>
                </Box>
            )}
        </BasePageLayout>
    );
};

export default TokensPage;