import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { Check, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validatePassword, validateUsername } from '../../../services/utils';
import { useForm } from '../../../hooks/useForm';
import api, { authenticateWithFirebase, StrapiRole, updateUser } from '../../../services/api';
import { UserContext } from '../../../context/UserContext';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../../../services/firebaseConfig';

const MerchantSignUpPage: React.FC = () => {
    const initialFormValues = {username: '', email: '', password: '', agreed: false};

    const validate = (values: typeof initialFormValues) => ({
        username: validateUsername(values.username),
        email: validateEmail(values.email),
        password: validatePassword(values.password),
        agreed: values.agreed,
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
    } = useForm({initialValues: initialFormValues, validate});

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const {fetchUser} = useContext(UserContext);

    const handleSignUp = async () => {
        if (isFormValid) {
            try {
                const res = await api.get('/users', {
                    params: {
                        filters: {username: {$eq: formData.username}},
                    },
                });

                if (res.data?.length) {
                    setError('User with this username already exists');
                    return;
                }

                const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
                const firebaseUser = userCredential.user;

                await updateProfile(firebaseUser, {displayName: formData.username});

                const idToken = await firebaseUser.getIdToken();

                const user = await authenticateWithFirebase(idToken, {
                    email: formData.email
                });

                await updateUser(user.id, {role: StrapiRole.Merchant, username: formData.username});

                await fetchUser();
                navigate('/merchant/home');
            } catch (err: any) {
                console.error('Registration error:', err);
                setError('Registration failed. Please try again.');
            }
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
                minHeight="100dvh"
            >
                <Box component="form">
                    <Typography variant="h1" align="left">
                        Merchant Sign up
                    </Typography>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Merchant name
                        </Typography>
                        <TextField
                            id="username"
                            type="text"
                            value={formData.username}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateUsername(formData.username) && formTouched.username}
                            helperText={!validateUsername(formData.username) && formTouched.username ? 'Username must be at least 3 characters long' : ''}
                        />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Email
                        </Typography>
                        <TextField
                            id="email"
                            type="email"
                            autoComplete="username"
                            value={formData.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateEmail(formData.email) && formTouched.email}
                            helperText={!validateEmail(formData.email) && formTouched.email ? 'Please enter a valid email address' : ''}
                            InputProps={{
                                endAdornment: validateEmail(formData.email) && formTouched.email ? (
                                    <InputAdornment position="end">
                                        <Check color="success"/>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Password
                        </Typography>
                        <TextField
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validatePassword(formData.password) && formTouched.password}
                            helperText={!validatePassword(formData.password) && formTouched.password ? 'Password must be at least 8 characters long and include at least one letter and one number.' : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            color="info"
                                            sx={{marginRight: '-10px'}}
                                        >
                                            {showPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="agreed"
                                checked={Boolean(formData.agreed)}
                                onChange={handleInputChange}
                                color="primary"
                            />
                        }
                        label={
                            <Typography variant="body1" sx={{marginBottom: 0}}>
                                Agree the terms of use and privacy policy
                            </Typography>
                        }
                        sx={{marginBottom: '30px'}}
                    />

                    {error && <Typography color="error">{error}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSignUp}
                        disabled={!isFormValid}
                    >
                        Sign up
                    </Button>
                </Box>
                <div></div>
            </Box>
        </Container>
    );
};

export default MerchantSignUpPage;