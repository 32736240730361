import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { formatPhoneNumber, validatePhoneNumber, validateUsername } from '../../../services/utils';
import { auth } from '../../../services/firebaseConfig';
import { useForm } from '../../../hooks/useForm';
import api from '../../../services/api';
import { Check } from '@mui/icons-material';
import { maskPhoneNumber } from './PhoneInputMask';

const UserSignUpPage: React.FC = () => {
    const location = useLocation();
    const {phoneNumber, url} = location.state || {};
    const initialFormValues = {username: '', phoneNumber: '', agreed: false};

    const validate = (values: typeof initialFormValues) => ({
        username: validateUsername(values.username),
        phoneNumber: validatePhoneNumber(values.phoneNumber),
        agreed: values.agreed,
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
        setFormData
    } = useForm({initialValues: initialFormValues, validate});

    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [isSendingCode, setIsSendingCode] = useState(false);

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            phoneNumber: maskPhoneNumber(phoneNumber)
        }));
    }, [phoneNumber, setFormData]);

    useEffect(() => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': () => {
                },
                'expired-callback': () => {
                    window.recaptchaVerifier.clear();
                },
            });
        }
    }, []);

    const handleSignUp = async () => {
        if (isFormValid) {
            setIsSendingCode(true);
            try {
                const res = await api.get('/users', {
                    params: {
                        filters: {username: {$eq: formData.username}},
                    },
                });

                if (res.data?.length) {
                    setError('User with this username already exists');
                    return;
                }

                const appVerifier = window.recaptchaVerifier;
                window.confirmationResult = await signInWithPhoneNumber(auth, formatPhoneNumber(formData.phoneNumber), appVerifier);
                navigate('/verify-phone', {
                    state: {
                        username: formData.username,
                        phoneNumber: `${formData.phoneNumber}`,
                        url
                    },
                });
            } catch (err) {
                setError('Failed to send verification code. Please try again.');
                window.recaptchaVerifier.clear();
            } finally {
                setIsSendingCode(false);
            }
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                minHeight="100dvh"
            >
                <Box component="form"
                     width={1} display="flex" flexDirection="column" justifyContent="space-between">
                    <Typography variant="h1" align="left">
                        Sign up
                    </Typography>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1">
                            Username
                        </Typography>
                        <TextField
                            id="username"
                            type="text"
                            value={formData.username}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validateUsername(formData.username) && formTouched.username}
                            helperText={!validateUsername(formData.username) && formTouched.username ? 'Username must be at least 3 characters long' : ''}
                        />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1">
                            Phone Number
                        </Typography>
                        <TextField
                            id="phoneNumber"
                            type="tel"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            placeholder={'(___)-___-____'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><Typography
                                        mb={0}>+1</Typography></InputAdornment>),
                                endAdornment: validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber ? (
                                    <InputAdornment position="end">
                                        <Check color="success"/>
                                    </InputAdornment>
                                ) : null,
                            }}
                            error={!validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber}
                            helperText={!validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber ? 'Please enter a valid phone number' : ''}
                        />
                    </FormControl>

                    <div id="recaptcha-container"></div>

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="agreed"
                                checked={Boolean(formData.agreed)}
                                onChange={handleInputChange}
                                color="primary"
                            />
                        }
                        label={
                            <Typography variant="body1" sx={{marginBottom: 0}}>
                                Agree the terms of use and privacy policy
                            </Typography>
                        }
                        sx={{marginBottom: '30px', marginTop: '10px'}}
                    />

                    {error && <Typography color="error">{error}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSignUp}
                        disabled={!isFormValid || isSendingCode}
                    >
                        {isSendingCode ? 'Sending code...' : 'Sign up'}
                    </Button>
                </Box>
                <div></div>
            </Box>
        </Container>
    );
};

export default UserSignUpPage;