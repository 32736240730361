import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, FormControl, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { Check } from '@mui/icons-material';
import { formatPhoneNumber, validatePhoneNumber } from '../../../services/utils';
import { useForm } from '../../../hooks/useForm';
import { UserContext } from '../../../context/UserContext';
import api, { jwtTokenKey } from '../../../services/api';
import { Role } from '../../../components/PrivateRoute';
import { auth } from '../../../services/firebaseConfig';
import { maskPhoneNumber, PhoneInputMask } from './PhoneInputMask';

const UserLoginPage: React.FC = () => {
    const location = useLocation();
    const {phoneNumber, url} = location.state || {};
    const initialFormValues = {phoneNumber: ''};

    const validate = (values: typeof initialFormValues) => ({
        phoneNumber: validatePhoneNumber(values.phoneNumber),
    });

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
        setFormData
    } = useForm({initialValues: initialFormValues, validate});

    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [isSendingCode, setIsSendingCode] = useState(false);
    const {user} = useContext(UserContext);

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            phoneNumber: maskPhoneNumber(phoneNumber)
        }));
    }, [phoneNumber, setFormData]);

    useEffect(() => {
        api.get('/users', {
            params: {
                filters: {phoneNumber: {$eq: phoneNumber}},
            },
        }).then(res => {
            if (!res.data?.length) {
                navigate('/signup', {state: {phoneNumber, url}})
            } else {
                if (!window.recaptchaVerifier) {
                    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                        'size': 'invisible',
                        'callback': () => {
                        },
                        'expired-callback': () => {
                            window.recaptchaVerifier.clear();
                        },
                    });
                }
            }
        });
    }, [navigate, phoneNumber, url]);

    useEffect(() => {
        if (localStorage.getItem(jwtTokenKey)) {
            if (user?.role === Role.Merchant) {
                navigate('/merchant/home');
            } else {
                navigate(url ?? '/home');
            }
        }
    }, [navigate, url, user?.role]);

    const handleLogin = async () => {
        if (isFormValid || !isSendingCode) {
            setIsSendingCode(true);
            try {
                const appVerifier = window.recaptchaVerifier;
                window.confirmationResult = await signInWithPhoneNumber(auth, formatPhoneNumber(formData.phoneNumber), appVerifier);
                navigate('/verify-phone', {state: {phoneNumber: `+1${formData.phoneNumber}`, url}});
            } catch (err) {
                setError('Failed to send verification code. Please try again.');
                window.recaptchaVerifier.clear();
            } finally {
                setIsSendingCode(false);
            }
        }
    };

    return (
        <Container maxWidth="xs" sx={{px: 3}}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                minHeight="100dvh"
            >
                <Box component="form"
                     width={1} minHeight="50dvh" display="flex" flexDirection="column" justifyContent="space-between">
                    <Typography variant="h1" align="left">
                        Sign in
                    </Typography>

                    <FormControl fullWidth variant="outlined" sx={{marginBottom: '20px'}}>
                        <Typography variant="body1" fontWeight={600}>
                            Phone Number
                        </Typography>
                        <TextField
                            id="phoneNumber"
                            type="tel"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={!validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber}
                            helperText={!validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber ? 'Please enter a valid phone number' : ''}
                            placeholder={'(___)-___-____'}
                            InputProps={{
                                inputComponent: PhoneInputMask,
                                startAdornment: (
                                    <InputAdornment position="start"><Typography
                                        mb={0}>+1</Typography></InputAdornment>),
                                endAdornment: validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber ? (
                                    <InputAdornment position="end">
                                        <Check color="success"/>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </FormControl>

                    <div id="recaptcha-container"></div>

                    {error && <Typography color="error">{error}</Typography>}

                    <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleLogin}
                            disabled={!isFormValid && !phoneNumber}
                        >
                            {isSendingCode ? 'Sending code...' : 'Sign in'}
                        </Button>
                        <Link href="/merchant/login" variant="body2">
                            Merchant Sign in
                        </Link>
                        <Typography variant="body1">
                            New customer?{' '}
                            <Link href="/signup" variant="body2">
                                Create new account
                            </Link>
                        </Typography>
                    </Box>
                </Box>

                <div></div>
            </Box>
        </Container>
    );
};

export default UserLoginPage;