import React, { FC, useState } from 'react';
import { Avatar, Box, Collapse, Stack, Typography } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { getDateTimeText } from '../../../services/utils';
import { Transaction } from '../../merchant/transactions/MerchantTransactions';
import { User } from '../../../context/UserContext';
import { UserTokenStatus } from '../my-tokens/MyTokens';
import { calculateTimeLeft } from '../../merchant/tokens/utils';
import { ReactComponent as TimerIcon } from '../../../icons/timer.svg';

interface UserTransactionItemProps {
    transaction: Transaction;
    user: User;
}

const UserTransactionItem: FC<UserTransactionItemProps> = ({transaction, user}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const transactionDate = new Date(transaction.createdAt);
    const isReceiver = transaction.recipientPhoneNumber === user.phoneNumber;

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    const formatPhoneNumber = (phoneNumber: string): string => {
        let cleaned = phoneNumber.replace(/^\+1/, '');
        cleaned = cleaned.replace(/\D/g, '');
        if (cleaned.length !== 10) {
            return phoneNumber;
        }
        return `+1 (${cleaned.slice(0, 3)})-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    };

    const expirationDate = new Date(new Date(transaction.updatedAt).getTime() + 72 * 60 * 60 * 1000);
    const timeLeft = calculateTimeLeft(expirationDate, true);

    return (
        <Box
            mb={1.5}
            sx={{
                borderRadius: '12px',
                overflow: 'hidden',
            }}
        >
            <Box
                onClick={handleClick}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                gap={2}
                sx={{
                    cursor: 'pointer',
                    bgcolor: 'rgba(245, 245, 245, 1)',
                }}
            >
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            bgcolor: 'rgba(57, 57, 67, 1)',
                            mr: 2,
                            border: transaction.token.imageUrl ? '' : '1px solid black',
                            height: 40,
                            width: 40,
                        }}
                        src={transaction.token.imageUrl || ''}
                    >
                        {!transaction.token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                    </Avatar>
                    <Stack>
                        <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                            {transaction.status === UserTokenStatus.Tossed
                                ? 'Accepted'
                                : transaction.status}
                        </Typography>
                        <Typography
                            fontWeight={500}
                            fontSize={10}
                            sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                        >
                            {getDateTimeText(transactionDate)}
                        </Typography>
                    </Stack>
                </Box>

                <Stack textAlign="end" mr={1}>
                    <Typography fontWeight={600} sx={{color: 'rgba(26, 29, 37, 1)'}}>
                        {transaction.token.itemName} ({transaction.quantity})
                    </Typography>
                    <Typography
                        fontWeight={500}
                        fontSize={10}
                        sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                    >
                        {transaction.token.merchant?.username}
                    </Typography>
                </Stack>
            </Box>

            <Collapse in={isExpanded}>
                <Stack p={2}>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={600} fontSize={12}>
                            {isReceiver ? 'Tosser' : 'Receiver'}:
                        </Typography>
                        <Typography fontSize={12} color={'rgba(0, 0, 0, 0.5)'}>
                            {formatPhoneNumber(
                                isReceiver ? transaction.tosserPhoneNumber : transaction.recipientPhoneNumber
                            )}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={600} fontSize={12}>
                            Merchant:
                        </Typography>
                        <Typography fontSize={12} color={'rgba(0, 0, 0, 0.5)'}>
                            {transaction.token.merchant?.username}, {transaction.token.merchant?.address}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={600} fontSize={12}>
                            Quantity:
                        </Typography>
                        <Typography fontSize={12} color={'rgba(0, 0, 0, 0.5)'}>
                            {transaction.quantity}
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={600} fontSize={12}>
                            Price:
                        </Typography>
                        <Typography fontSize={12} color={'rgba(0, 0, 0, 0.5)'}>
                            ${(+transaction.token.price * transaction.quantity * (1 - (transaction.token.discount) / 100)).toFixed(2)}
                        </Typography>
                    </Box>
                    {transaction.status === UserTokenStatus.Spinning &&
                        <Box display={'flex'} alignItems="center" justifyContent="space-between" gap={2}>
                            <Typography color={'rgba(75, 78, 82, 1)'} fontWeight={600} fontSize={12}>
                                Expiration:
                            </Typography>
                            <Box display='flex' alignItems='center' gap='5px' sx={{
                                color: 'rgba(59, 186, 79, 1)',
                            }}>
                                <TimerIcon/>
                                <Typography
                                    fontWeight={700}
                                    color="inherit"
                                    fontSize={10}
                                    mb={0}
                                >
                                    {timeLeft}
                                </Typography>
                            </Box>
                        </Box>}
                </Stack>
            </Collapse>
        </Box>
    );
};

export default UserTransactionItem;