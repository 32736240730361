import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import qs from 'qs';
import { Close, CurrencyExchange, Person } from '@mui/icons-material';
import { Token } from '../tokens/TokenDetailsPage';
import NavigationButtons from './NavigationButtons';
import FilterDropdown from './FilterDropdown';
import { Transaction } from '../transactions/MerchantTransactions';
import MerchantTransactionItem from '../transactions/MerchantTransactionItem';
import { UserContext } from '../../../context/UserContext';
import useSnackbar from '../../../hooks/useSnackbar';
import api, { jwtTokenKey } from '../../../services/api';
import BasePageLayout from '../../../components/BasePageLayout';
import BarChartIcon from '@mui/icons-material/BarChart';
import Chart from './Chart';
import { UserTokenStatus } from '../../user/my-tokens/MyTokens';

const MerchantHome: React.FC = () => {
    const {user, avatarUrl, fetchUser} = useContext(UserContext);
    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();

    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [selectedToken, setSelectedToken] = useState<string>('all');
    const [appliedTokenFilter, setAppliedTokenFilter] = useState<string>('all');
    const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const filterButtonRef = React.useRef<HTMLButtonElement>(null);

    const [remaining, setRemaining] = useState<number>(0);
    const [redeemed, setRedeemed] = useState<number>(0);

    const fetchUserTokens = useCallback(async () => {
        if (user) {
            try {
                const query = qs.stringify(
                    {
                        filters: {
                            merchant: {id: {$eq: user.id}},
                            isActive: true,
                            expirationDate: {$gte: new Date().toISOString()}
                        }
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/tokens?${query}`);
                const fetchedTokens: Token[] = response.data.data.map((token: any) => ({
                    id: token.id,
                    ...token.attributes,
                }));

                setTokens(fetchedTokens);

                const totalRemaining = fetchedTokens.reduce((sum, token) => sum + (token.quantity || 0), 0);
                setRemaining(totalRemaining);
            } catch (error) {
                showSnackbar('Error fetching tokens', 'error');
                console.error('Error fetching tokens:', error);
            }
        }
    }, [user, showSnackbar]);

    const fetchRedeemedTransactions = useCallback(
        async () => {
            if (!user) return;

            try {
                const conditions: any[] = [
                    {
                        token: {
                            merchant: {
                                id: {
                                    $eq: user.id,
                                },
                            },
                        },
                    },
                    {
                        status: {
                            $eq: UserTokenStatus.Redeemed,
                        },
                    },
                ];

                const query = qs.stringify(
                    {
                        filters: {$and: conditions},
                        pagination: {pageSize: 0},
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/transactions?${query}`);

                setRedeemed(response.data.meta.pagination.total);
            } catch (error) {
                console.error('Error fetching redeemed transactions:', error);
                showSnackbar('Error fetching redeemed transactions', 'error');
            }
        },
        [user, showSnackbar]
    );

    const fetchRecentTransactions = useCallback(
        async (tokenFilter: string | null = null) => {
            if (!user) return;

            setLoadingTransactions(true);

            try {
                const conditions: any[] = [
                    {
                        token: {
                            merchant: {
                                id: {
                                    $eq: user.id,
                                },
                            },
                        },
                    },
                ];

                if (tokenFilter && tokenFilter !== 'all') {
                    conditions.push({
                        token: {
                            id: {
                                $eq: tokenFilter.trim(),
                            },
                        },
                    });
                }

                const query = qs.stringify(
                    {
                        filters: conditions.length > 0 ? {$and: conditions} : {},
                        pagination: {
                            page: 1,
                            pageSize: 4,
                        },
                        sort: ['createdAt:desc'],
                        populate: {
                            token: {
                                populate: ['merchant'],
                            },
                        },
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/transactions?${query}`);

                const fetchedTransactions: Transaction[] = response.data.data.map((item: any) => {
                    const transactionAttributes = item.attributes;
                    const tokenData = transactionAttributes.token.data;
                    const tokenAttributes = tokenData.attributes;
                    const merchantData = tokenAttributes.merchant.data;

                    const token: Token = {
                        id: tokenData.id,
                        ...tokenAttributes,
                        merchant: {
                            id: merchantData?.id,
                            ...merchantData?.attributes,
                        },
                    };

                    const transaction: Transaction = {
                        id: item.id,
                        token: token,
                        tosserPhoneNumber: transactionAttributes.tosserPhoneNumber,
                        recipientPhoneNumber: transactionAttributes.recipientPhoneNumber,
                        quantity: transactionAttributes.quantity,
                        status: transactionAttributes.status,
                        createdAt: new Date(transactionAttributes.createdAt),
                        updatedAt: new Date(transactionAttributes.updatedAt),
                    };

                    return transaction;
                });

                setTransactions(fetchedTransactions);
            } catch (error) {
                console.error('Error fetching transactions:', error);
                showSnackbar('Error fetching transactions', 'error');
            } finally {
                setLoadingTransactions(false);
            }
        },
        [user, showSnackbar]
    );

    useEffect(() => {
        const token = localStorage.getItem(jwtTokenKey);
        if (token && !user) {
            fetchUser();
        }
    }, [user, fetchUser]);

    useEffect(() => {
        fetchUserTokens();
        fetchRedeemedTransactions();
    }, [fetchUserTokens, fetchRedeemedTransactions]);

    useEffect(() => {
        fetchRecentTransactions(appliedTokenFilter);
    }, [fetchRecentTransactions, appliedTokenFilter]);

    const handleTokenFilterChange = (value: string) => {
        setSelectedToken(value);
    };

    const handleApplyFilter = () => {
        setAppliedTokenFilter(selectedToken);
        setFilterOpen(false);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (filterButtonRef.current && filterButtonRef.current.contains(event.target as Node)) {
            return;
        }
        setFilterOpen(false);
    };

    return (
        <BasePageLayout
            showBack={false}
            avatarUrl={avatarUrl || ''}
            username={user?.username}
        >
            <NavigationButtons onNavigate={navigate}/>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography fontSize={12} fontWeight={700} mb={0}>
                    Statistics
                </Typography>

                <Box display="flex" gap={1.5}>
                    {appliedTokenFilter !== 'all' && (
                        <Chip
                            label={tokens.find(token => token.id === +appliedTokenFilter)?.itemName}
                            size="small"
                            deleteIcon={
                                <Close
                                    sx={{
                                        fontSize: '12px !important',
                                        color: 'white !important',
                                    }}
                                />
                            }
                            onDelete={() => {
                                setSelectedToken('all');
                                setAppliedTokenFilter('all');
                            }}
                            sx={{
                                fontSize: '10px',
                                fontWeight: '500',
                                borderRadius: '9px',
                                backgroundColor: 'rgba(57, 57, 67, 1)',
                                color: 'white !important',
                            }}
                        />
                    )}

                    <Tooltip enterTouchDelay={0}
                             title={!tokens || tokens.length === 0 ? "No valid tokens" : ""}>
                        <div>
                            <Button
                                ref={filterButtonRef}
                                sx={{
                                    height: '25px',
                                    width: '60px',
                                    padding: 0,
                                    backgroundColor: 'rgba(245, 245, 245, 1)',
                                    color: 'rgba(57, 57, 67, 1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(57, 57, 67, 0.05)',
                                    },
                                }}
                                disabled={!tokens || tokens.length === 0}
                                onClick={() => setFilterOpen((prev) => !prev)}
                                startIcon={<TuneIcon sx={{fontSize: '12px !important'}}/>}
                                endIcon={<KeyboardArrowDownIcon sx={{fontSize: '12px !important'}}/>}
                            />
                        </div>
                    </Tooltip>
                    <FilterDropdown
                        tokens={tokens}
                        selectedToken={selectedToken}
                        onChange={handleTokenFilterChange}
                        onApply={handleApplyFilter}
                        open={filterOpen}
                        onClickAway={handleClickAway}
                    />
                </Box>
            </Box>

            <Box display="flex" gap={1} mt={1}>
                <Stack
                    height="88px"
                    width={1 / 2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="0.5px solid rgba(224, 224, 224, 1)"
                    bgcolor="rgba(96, 129, 234, 1)"
                    borderRadius="12px"
                >
                    <Typography fontSize={18} fontWeight={600} mb={0} color="white">
                        ${user?.statistics?.totalSold || 0}
                    </Typography>
                    <Typography fontSize={12} fontWeight={500} color="white">
                        Total Sold
                    </Typography>
                </Stack>
                <Box
                    height="88px"
                    width={1 / 2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    border="0.5px solid rgba(224, 224, 224, 1)"
                    borderRadius="12px"
                >
                    <Chart
                        value={remaining / (remaining + (user?.statistics?.totalBought || 0)) * 100 || 0}
                        color="rgba(96, 129, 234, 1)"
                        icon={BarChartIcon}
                    />
                    <Stack>
                        <Typography fontSize={18} fontWeight={600} mb={0}>
                            {remaining}
                        </Typography>
                        <Typography fontSize={12} fontWeight={500} color="rgba(160, 157, 180, 1)">
                            Remaining
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Box display="flex" gap={1} mt={1}>
                <Box
                    height="88px"
                    width={1 / 2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    border="0.5px solid rgba(224, 224, 224, 1)"
                    borderRadius="12px"
                >
                    <Chart
                        value={(user?.statistics?.totalBought || 0) / (remaining + (user?.statistics?.totalBought || 0)) * 100 || 0}
                        color="rgba(59, 186, 79, 1)"
                        icon={Person}
                    />
                    <Stack>
                        <Typography fontSize={18} fontWeight={600} mb={0}>
                            {user?.statistics?.totalBought || 0}
                        </Typography>
                        <Typography fontSize={12} fontWeight={500} color="rgba(160, 157, 180, 1)">
                            Tossed
                        </Typography>
                    </Stack>
                </Box>
                <Box
                    height="88px"
                    width={1 / 2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    border="0.5px solid rgba(224, 224, 224, 1)"
                    borderRadius="12px"
                >
                    <Chart
                        value={redeemed / (remaining + (user?.statistics?.totalBought || 0)) * 100 || 0}
                        color="rgba(47, 196, 224, 1)"
                        icon={CurrencyExchange}
                    />
                    <Stack>
                        <Typography fontSize={18} fontWeight={600} mb={0}>
                            {redeemed}
                        </Typography>
                        <Typography fontSize={12} fontWeight={500} color="rgba(160, 157, 180, 1)">
                            Redeemed
                        </Typography>
                    </Stack>
                </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
                <Typography fontSize={12} fontWeight={700}>
                    Recent Transactions
                </Typography>

                <RouterLink to="/merchant/transactions" style={{textDecoration: 'none'}}>
                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        color="rgba(66, 128, 239, 1)"
                        variant="body2"
                        mb={0}
                        height="fit-content"
                    >
                        See All Transactions
                    </Typography>
                </RouterLink>
            </Box>

            {loadingTransactions ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress/>
                </Box>
            ) : transactions.length > 0 ? (
                transactions.map((transaction) => (
                    <MerchantTransactionItem key={transaction.id} transaction={transaction}/>
                ))
            ) : (
                <Typography>No transactions available.</Typography>
            )}
        </BasePageLayout>
    );
};

export default MerchantHome;