import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Fab,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import BasePageLayout from '../../components/BasePageLayout';
import { Token } from '../merchant/tokens/TokenDetailsPage';
import { User, UserContext } from '../../context/UserContext';
import { formatPhoneNumber, validatePhoneNumber } from '../../services/utils';
import { useForm } from '../../hooks/useForm';
import api, { API_URL } from '../../services/api';
import useSnackbar from '../../hooks/useSnackbar';
import { Notification } from '../merchant/notifications/notification';
import { BoughtToken, UserTokenStatus } from './my-tokens/MyTokens';
import { PhoneInputMask } from './auth/PhoneInputMask';

const ProceedToPayment: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {token, merchant, boughtToken} = location?.state
        ? (location.state as { token: Token; merchant: User; boughtToken?: BoughtToken })
        : {
            token: null,
            merchant: null,
            boughtToken: null,
        };

    const isEditMode = Boolean(boughtToken);

    const [quantity, setQuantity] = useState<number>(isEditMode ? boughtToken!.quantity : 1);
    const [totalPrice, setTotalPrice] = useState<string>('');
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {user} = useContext(UserContext);
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        if (!location.state) {
            navigate('/toss');
        }
    }, [location.state, navigate]);

    const initialFormValues = {
        phoneNumber: '',
        message: '',
    };

    const validate = (values: typeof initialFormValues) => {
        const phoneNumberValid = validatePhoneNumber(values.phoneNumber);
        const formattedPhoneNumber = formatPhoneNumber(values.phoneNumber);
        const isOwnNumber = formattedPhoneNumber === user?.phoneNumber;
        return {
            phoneNumber: phoneNumberValid && !isOwnNumber,
        };
    };

    const {
        formData,
        formTouched,
        isFormValid,
        handleInputChange,
        handleBlur,
        setFormData,
    } = useForm({
        initialValues: initialFormValues,
        validate,
    });

    const handleQuantityChange = (change: number) => {
        if (isEditMode) return;
        setQuantity((prev) => Math.max(1, prev + change));
    };

    useEffect(() => {
        if (!token) {
            return;
        }
        setTotalPrice((+token.price * quantity * (1 - token.discount / 100)).toFixed(2));
    }, [quantity, token]);

    const handlePayment = async () => {
        if (!isFormValid || !token) {
            setSubmitError('Please enter a valid phone number.');
            return;
        }
        setSubmitError(null);

        let {phoneNumber, message} = formData;
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

        setLoading(true);

        try {
            const recipientUserPromise = api.get('/users', {
                params: {
                    filters: {phoneNumber: {$eq: formattedPhoneNumber}},
                },
            });

            if (isEditMode) {
                const updateBoughtTokenPromise = api.put(`/bought-tokens/${boughtToken!.id}`, {
                    data: {
                        recipientPhoneNumber: formattedPhoneNumber,
                        status: UserTokenStatus.Spinning,
                    },
                });

                const updateTransactionPromise = api.put(`/transactions/${boughtToken!.transaction.id}`, {
                    data: {
                        recipientPhoneNumber: formattedPhoneNumber,
                        status: UserTokenStatus.Spinning,
                    },
                });

                const [recipientResponse] = await Promise.all([
                    recipientUserPromise,
                    updateBoughtTokenPromise,
                    updateTransactionPromise,
                ]);

                const recipientUser = recipientResponse.data[0];

                if (recipientUser) {
                    const notificationData = {
                        text: `${user?.username} sent you a token`,
                        user: recipientUser.id,
                        message,
                        tokenName: token.itemName,
                        tokenMerchantName: merchant.username,
                        senderUsername: user?.username,
                    } as Notification;

                    await api.post('/notifications', {
                        data: notificationData,
                    });
                }

                const shareUrl = `${API_URL}/my-tokens?phoneNumber=${encodeURIComponent(formattedPhoneNumber)}`;
                const greeting = formData.message || '';
                const shareText = `${greeting} You can claim your token here: `;

                navigate('/all-right', {state: {shareUrl, shareText}});
                showSnackbar('Token updated successfully', 'success');
            } else {
                if (token.quantity < quantity) {
                    setSubmitError('Merchant does not have enough tokens.');
                    setLoading(false);
                    return;
                }

                const data = {
                    token: {id: token.id},
                    tosserPhoneNumber: user?.phoneNumber,
                    recipientPhoneNumber: formattedPhoneNumber,
                    quantity,
                    status: UserTokenStatus.Spinning,
                } as BoughtToken;

                const createTransactionPromise = api.post(`/transactions`, {
                    data: {
                        token: token.id,
                        tosserPhoneNumber: user?.phoneNumber,
                        recipientPhoneNumber: formattedPhoneNumber,
                        quantity,
                        status: UserTokenStatus.Spinning,
                    },
                });

                const tokenUpdatePromise = api.put(`/tokens/${token.id}`, {
                    data: {quantity: token.quantity - quantity},
                });

                const [transactionResponse, recipientResponse] = await Promise.all([
                    createTransactionPromise,
                    recipientUserPromise,
                    tokenUpdatePromise,
                ]);

                const createdTransaction = transactionResponse.data.data;

                await api.post(`/bought-tokens`, {
                    data: {
                        ...data,
                        transaction: createdTransaction.id,
                    },
                });

                const merchantStatistics = merchant.statistics;

                const newTotalSold = (merchantStatistics?.totalSold || 0) + parseFloat(totalPrice);
                const newTotalBought = (merchantStatistics?.totalBought || 0) + quantity;

                await api.put(`/users/${merchant.id}`, {
                    statistics: {
                        totalSold: newTotalSold,
                        totalBought: newTotalBought,
                    },
                });

                const recipientUser = recipientResponse.data[0];

                const notificationsPromises = [];

                if (recipientUser) {
                    const notificationData = {
                        text: `${user?.username} sent you a token`,
                        user: recipientUser.id,
                        message,
                        tokenName: token.itemName,
                        tokenMerchantName: merchant.username,
                        senderUsername: user?.username,
                    } as Notification;

                    notificationsPromises.push(
                        api.post('/notifications', {
                            data: notificationData,
                        })
                    );
                }

                const merchantNotificationData = {
                    text: `The ${token.itemName} (${quantity}) token was bought`,
                    user: merchant.id,
                };

                notificationsPromises.push(
                    api.post('/notifications', {
                        data: merchantNotificationData,
                    })
                );

                await Promise.all(notificationsPromises);

                const shareUrl = `https://dev.tokntoss.com/my-tokens?phoneNumber=${encodeURIComponent(formattedPhoneNumber)}`;
                const greeting = formData.message || '';
                const shareText = `${greeting} You can claim your token here: `;

                navigate('/all-right', {state: {shareUrl, shareText}});
                showSnackbar('Tokens issued successfully', 'success');
            }
        } catch (error) {
            console.error('Error issuing/updating tokens', error);
            showSnackbar('Failed to process the token', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <BasePageLayout>
            <Box mb={3}>
                <TextField
                    fullWidth
                    placeholder="Recipient phone number"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    sx={{
                        mb: 2,
                    }}
                    error={
                        (!validatePhoneNumber(formData.phoneNumber) ||
                            formatPhoneNumber(formData.phoneNumber) === user?.phoneNumber) &&
                        formTouched.phoneNumber
                    }
                    helperText={
                        !validatePhoneNumber(formData.phoneNumber) && formTouched.phoneNumber
                            ? 'Invalid phone number format'
                            : formTouched.phoneNumber && formatPhoneNumber(formData.phoneNumber) === user?.phoneNumber
                                ? 'You cannot use your own phone number.'
                                : ''
                    }
                    margin="normal"
                    InputProps={{
                        inputComponent: PhoneInputMask,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography mb={0}>+1</Typography>
                            </InputAdornment>
                        ),
                    }}
                />
                <Box display={'flex'} gap={1}>
                    {['My treat!', 'Happy Birthday!', 'Congrats!'].map((text) => <Chip
                        label={text}
                        key={text}
                        size="small"
                        sx={{
                            fontSize: '10px',
                            fontWeight: '500',
                            borderRadius: '9px',
                            border: '1px solid rgba(0, 0, 0, 0.5)',
                            backgroundColor: 'white',
                            color: 'rgba(28, 27, 31, 1) !important',
                            '&:hover': {
                                backgroundColor: 'rgba(245, 245, 245, 1)',
                            }
                        }}
                        onClick={() => {
                            setFormData({
                                message: formData.message + text,
                                phoneNumber: formData.phoneNumber
                            })
                        }}
                    />)}
                </Box>
                <TextField
                    fullWidth
                    placeholder="Message (optional)"
                    id="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    margin="normal"
                    multiline
                    minRows={3}
                    inputProps={{
                        maxLength: 255
                    }}
                    sx={{
                        mt: 1,
                        '& .MuiInputBase-root': {
                            height: 'auto',
                        },
                        '& .MuiInputBase-input': {
                            maxHeight: '50px',
                        },
                    }}
                />

                <Stack
                    my={1}
                    p={2}
                    gap={2}
                    sx={{
                        '.MuiTypography-root': {
                            mb: 0,
                        },
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontSize={13} color="rgba(75, 78, 82, 1)">
                            Token
                        </Typography>
                        <Typography fontSize={18} fontWeight={500}>
                            {token?.itemName}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontSize={13} color="rgba(75, 78, 82, 1)">
                            Recipient
                        </Typography>
                        <Typography fontSize={16}>+1 {formData.phoneNumber}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography fontSize={13} color="rgba(75, 78, 82, 1)">
                            Merchant
                        </Typography>
                        <Stack>
                            <Typography fontSize={16}>{merchant?.username}</Typography>
                            {merchant?.address && (
                                <Typography fontSize={13}>{merchant.address}</Typography>
                            )}
                        </Stack>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" gap={1}>
                            <Typography fontSize={13} color="rgba(75, 78, 82, 1)">
                                Expiration
                            </Typography>
                            <Tooltip enterTouchDelay={0}
                                     title="If not accepted before the expiration, it will be returned to you.">
                                <InfoOutlined fontSize="small" color="primary"/>
                            </Tooltip>
                        </Box>
                        <Typography fontSize={13}>72h</Typography>
                    </Box>


                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography fontSize={13} color="rgba(75, 78, 82, 1)">
                            Quantity:
                        </Typography>
                        {isEditMode ? <Typography sx={{mb: 0, fontWeight: 600}}>
                            {quantity}
                        </Typography> : <Box display="flex" alignItems="center">
                            <Fab
                                size="small"
                                disabled={quantity <= 1}
                                sx={{
                                    bgcolor: 'rgba(255, 166, 41, 1)',
                                    minHeight: 25,
                                    minWidth: 25,
                                    height: 25,
                                    width: 25,
                                    fontFamily: 'auto',
                                    fontSize: '16px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: 'rgba(230, 150, 35, 1)',
                                    },
                                }}
                                onClick={() => handleQuantityChange(-1)}
                            >
                                <Typography sx={{color: 'white', mb: '3px !important'}}>
                                    -
                                </Typography>
                            </Fab>
                            <Typography sx={{mx: 2, mb: 0, fontWeight: 600}}>
                                {quantity}
                            </Typography>
                            <Fab
                                disabled={!token || quantity >= token.quantity}
                                sx={{
                                    bgcolor: 'rgba(255, 166, 41, 1)',
                                    color: 'white',
                                    minHeight: 25,
                                    minWidth: 25,
                                    height: 25,
                                    width: 25,
                                    fontFamily: 'auto',
                                    fontSize: '16px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: 'rgba(230, 150, 35, 1)',
                                    },
                                }}
                                onClick={() => handleQuantityChange(+1)}
                            >
                                <Typography sx={{color: 'white', mb: '4px !important'}}>
                                    +
                                </Typography>
                            </Fab>
                        </Box>}
                    </Box>

                </Stack>

                <Divider sx={{mt: 3}}></Divider>

                {!isEditMode && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <Typography fontSize={18} color="rgba(75, 78, 82, 1)" fontWeight={700}>
                            Total
                        </Typography>
                        <Typography fontSize={18} color="rgba(75, 78, 82, 1)" fontWeight={700}>
                            ${totalPrice}
                        </Typography>
                    </Box>
                )}

                {submitError && (
                    <Typography color="error" sx={{mt: 2}}>
                        {submitError}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        mt: 3,
                        bgcolor: 'rgba(57, 57, 67, 1)',
                        borderRadius: '12px',
                        color: '#FFF',
                        fontSize: '12px',
                    }}
                    onClick={handlePayment}
                    disabled={!isFormValid || loading}
                >
                    {loading ? (
                        <CircularProgress size={24} sx={{color: 'white'}}/>
                    ) : isEditMode ? (
                        'Toss'
                    ) : (
                        'Proceed to Payment'
                    )}
                </Button>
            </Box>
        </BasePageLayout>
    );
};

export default ProceedToPayment;